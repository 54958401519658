import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
  Typography,
  Skeleton,
  Stack
} from "@mui/material";
import { useCallback, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import ApiService from "../services/ApiService";
import MicIcon from "@mui/icons-material/Mic";
import "./style.css";
import dsLogo from "../assets/ds-logo.svg";

function Main() {
  const [textValue, setTextValue] = useState("");
  const [isConverting, setIsConverting] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout>();
  // const [recordingBlob, setRecordingBlob] = useState<Blob>();

  const _startTimer: () => void = useCallback(() => {
    const interval = setInterval(() => {
      setRecordingTime((time) => time + 1);
      //TODO: check for max file size!!!
    }, 1000);
    setTimerInterval(interval);
  }, [setRecordingTime, setTimerInterval]);

  const _stopTimer: () => void = useCallback(() => {
    timerInterval != null && clearInterval(timerInterval);
    setTimerInterval(undefined);
  }, [timerInterval, setTimerInterval]);

  /**
   * Calling this method would result in the recording to start. Sets `isRecording` to true
   */
  const startRecording: () => void = useCallback(() => {
    if (timerInterval != null) return;

    navigator.mediaDevices
      .getUserMedia({
        audio:
          {
            noiseSuppression: true,
            echoCancellation: true,
          } ?? true,
      })
      .then((stream) => {
        setIsRecording(true);
        const recorder: MediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        recorder.start();
        _startTimer();
      })
      .catch((err: DOMException) => {
        console.log(err.name, err.message, err.cause);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timerInterval,
    setIsRecording,
    setMediaRecorder,
    _startTimer,
    //setRecordingBlob,
  ]);

  /**
   * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
   */
  const stopRecording: () => void = useCallback(() => {
    mediaRecorder?.stop();
    _stopTimer();
    setRecordingTime(0);
    setIsRecording(false);
    setIsPaused(false);
  }, [
    mediaRecorder,
    setRecordingTime,
    setIsRecording,
    setIsPaused,
    _stopTimer,
  ]);

  const cancelRecording = () => {
    if (mediaRecorder !== undefined) {
        mediaRecorder.addEventListener(
          "dataavailable",
          (event) => {
            //setRecordingBlob(event.data);
            mediaRecorder.stream.getTracks().forEach((t) => t.stop());
            setMediaRecorder(undefined);
          },
          { once: true }
        );
    }
    stopRecording();
  };

  const pauseRecording: () => void = useCallback(() => {
    if (!isPaused) {
      setIsPaused(true);
      _stopTimer();
      mediaRecorder?.pause();
    }
  }, [isPaused, _stopTimer, mediaRecorder]);
  const resumeRecording: () => void = useCallback(() => {
    if (isPaused) {
      setIsPaused(false);
      mediaRecorder?.resume();
      _startTimer();
    }
  }, [isPaused, mediaRecorder, _startTimer]);

  const translateAndSaveToClipboard = () => {
    if (mediaRecorder !== undefined) {
      setIsConverting(true)
      const prom = new Promise<string>((resolve, reject) => {
        mediaRecorder.addEventListener(
          "dataavailable",
          (event) => {
            //setRecordingBlob(event.data);
            mediaRecorder.stream.getTracks().forEach((t) => t.stop());
            setMediaRecorder(undefined);
            let formData = new FormData();
            formData.append("recording", event.data as Blob);
            ApiService.sendAudio(formData)
              .then((response) => {
                setTextValue(response.text);
                setIsConverting(false)
                resolve(response.text);
              })
              .catch((error) => {
                console.error(error);
                setIsConverting(false)
                reject();
              });
          },
          { once: true }
        );
      });
      if (typeof ClipboardItem && navigator.clipboard.write) {
        const text = new ClipboardItem({
          "text/plain": prom,
        });
        navigator.clipboard.write([text]).catch(console.warn);
      } else {
        prom
          .then((response) => {
            navigator.clipboard.writeText(response);
          })
          .catch(console.warn);
      }
    }
    stopRecording();
  };

  return (
    <Container sx={{paddingLeft: {xs: 0, md: 2}, paddingRight: {xs: 0, md: 2}, height: "100%"}}>
      <Card sx={{height:"100%",  display: "flex", flexDirection: "column"}} >
        <CardContent sx={{flexGrow: 1, overflow: "auto"}}>
          <div className={"headerLine"}>
          <Typography
            gutterBottom
            variant="h5"
            sx={{ display: "inline-block", marginTop: "auto", marginBottom: 0}}
          >
            Whisper Preview
          </Typography>
          <img src={dsLogo} alt="Dialogshift logo" height={"40px"}/>
          </div>
          {isRecording && !isPaused && (
            <div style={{ display: "inline-block" }}>
              <span className={"blinking"}>
                <span className="dot"></span>
                Recording
              </span>
            </div>
          )}
          {isConverting &&(
        <Skeleton variant="rounded" width={"100%"} height={60}/>

          )}
          {!isConverting && (<TextField
            value={textValue}
            InputProps={{
              readOnly: false,
            }}
            onChange={(em) => setTextValue(em.target.value)}
            multiline
            fullWidth
            label="Transcribed text"
            sx={{maxHeight: "100%"}}
            className="textfield"
          ></TextField>)}

          <Stack direction="row" justifyContent="end">
          <Button onClick={() => navigator.clipboard.writeText(textValue)} disabled={isConverting} variant="outlined" sx={{textTransform: "none", height: 48, marginTop: 1}}>
            Copy text to clipboard
          </Button>
          </Stack>
        </CardContent>
        {!isRecording && (
          <CardActions>
            <Button
              variant="contained"
              size="large"
              startIcon={<MicIcon sx={{ height: "80px", width: "80px" }} />}
              onClick={() => startRecording()}
              sx={{ width: "100%", height: "100px", fontSize: "200%" }}
            >
            </Button>
          </CardActions>
        )}
        {isRecording && (
          <>
          <CardActions>
            {!isPaused && (
                <Button
                  onClick={() => pauseRecording()}
                  variant="outlined"
                  sx={{ width: "50%", textTransform: "none", height: 48 }}
                >
                  Pause
                </Button>
              )}
              {isPaused && (
                <Button
                  onClick={() => resumeRecording()}
                  variant="contained"
                  sx={{ width: "50%", textTransform: "none", height: 48 }}
                >
                  Resume
                </Button>
              )}

              <Button
                onClick={() => cancelRecording()}
                variant="outlined"
                color="error"
                sx={{ width: "50%", textTransform: "none", height: 48 }}
              >
                Cancel
              </Button>
            </CardActions>
            <CardActions>
              <Button
                onClick={() => translateAndSaveToClipboard()}
                variant={isPaused ? "outlined":"contained"}
                sx={{ width: "100%", height: "100px", textTransform: "none"}}
              >
                <div>
                  {mediaRecorder && (
                    <LiveAudioVisualizer
                      mediaRecorder={mediaRecorder}
                      width={300}
                      height={60}
                      barWidth={2}
                      gap={2}
                      fftSize={512}
                      maxDecibels={-10}
                      minDecibels={-80}
                      smoothingTimeConstant={0.4}
                    />
                  )}
                    <Typography>Stop recording and convert</Typography>
                </div>
              </Button>
            </CardActions>
          </>
        )}
      </Card>
    </Container>
  );
}

export default Main;

import keycloak from "../keycloak";
import { InternalAxiosRequestConfig } from "axios";

export default class UserService {
    static initKeycloak = (callback: () => void) => {
        keycloak
            .init({
                onLoad: "check-sso",
                //silentCheckSsoRedirectUri: window.location.origin + "/silent-sso.html",
                //pkceMethod: "S256",
                checkLoginIframe: false,
                enableLogging: true,
            })
            .then((authenticated) => {
                if (authenticated) {
                    callback();
                } else {
                    UserService.doLogin();
                }
            });
    };

    static isLoggedIn = () => !!keycloak.token;

    static doLogin = () => keycloak.login();

    static doLogout = () => keycloak.logout();

    static getUsername = () => keycloak.tokenParsed?.preferred_username;

    static getToken = () => keycloak.token;

    static updateToken = (callback: () => Promise<InternalAxiosRequestConfig>) =>
        keycloak
            .updateToken(5)
            .then(callback)
            .catch(UserService.doLogin) as Promise<InternalAxiosRequestConfig>;

    static hasRoles = (roles: string[]) =>
        roles.some(
            (role) => keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role),
        );
}

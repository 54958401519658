import axios, {
    AxiosError,
    InternalAxiosRequestConfig,
    AxiosResponse,
    Method,
} from "axios";
import axiosRetry from "axios-retry";
import UserService from "./UserService";

const instance = axios.create();

axiosRetry(instance, {
    retries: 3,
    retryDelay: (retryCount) => {
        return retryCount * 3000;
    },
    retryCondition: (error: AxiosError) => {
        if (error.response && error.response.status === 404) {
            return false;
        }

        if (error.config) return error.config.method === "get";

        return false;
    },
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (UserService.isLoggedIn()) {
        const cb = () => {
            if (config.headers) {
                config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            }

            return Promise.resolve(config);
        };

        return UserService.updateToken(cb);
    } else {
        UserService.doLogin();

        return config;
    }
});

export interface ApiServiceRequestOptions {
    url: string;
    showError?: boolean;
    skipTokenRefresh?: boolean;
    method?: Method;
    data?: { [key: string]: any };
    headers?: { [key: string]: any };
    params?: { [key: string]: any };
}

export default class ApiService {
    static getCoreEndpoint() {
        return process.env.REACT_APP_CORE_API_URL;
    }

    static getHelperEndpoint() {
        return process.env.REACT_APP_HELPER_API_URL;
    }

    static getAdminEndpoint() {
        return process.env.REACT_APP_ADMIN_API_URL;
    }

    static request({
                       url,
                       method = "GET",
                       data = {},
                       headers = {},
                       showError = true,
                       params = {},
                   }: ApiServiceRequestOptions): Promise<any> {
        return new Promise((resolve, reject) => {
            return instance
                .request<any>({
                    headers,
                    url,
                    method,
                    data,
                    params,
                })
                .then((response: AxiosResponse) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    const status: number =
                        error.response && error.response.status
                            ? isNaN(error.response.status)
                                ? -1
                                : error.response.status
                            : -1;
                    if (status !== 404 && showError) {
                        if (
                            error.response &&
                            error.response.data &&
                            Object.keys(error.response.data).includes("error")
                        ) {
                            console.log(error.response.data["error"]);
                        }
                    }
                    reject(status);
                });
        });
    }

    static sendAudio(data: FormData): Promise<any> {
        return ApiService.request({
            url: `https://s2ttest.dev.dialogshift.com`,
            method: "post",
            data: data,
        })
    }
};
